/* src/pages/GamesDisplayScreen.css */

/* Base styles */
@import url('https://fonts.googleapis.com/css2?family=Varela+Round&display=swap');

.games-display-screen {
  min-height: 100vh;
  padding: 40px 20px;
  background: linear-gradient(135deg, #4F9FD9, #9CD8FA);
}

.section-title {
  color: white;
  font-size: 3.5rem;
  text-align: center;
  margin-bottom: 40px;
  font-family: 'Varela Round', sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

.section-subtitle {
  color: #4F9FD9;
  font-size: 2.5rem;
  margin: 20px 0 30px;
  font-family: 'Varela Round', sans-serif;
  text-shadow: none;
  position: relative;
  display: flex;
  align-items: center;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

.section-subtitle::after {
  content: '';
  flex-grow: 1;
  height: 2px;
  background: rgba(79, 159, 217, 0.3);
  margin-left: 20px;
}

/* Grid layouts */
.games-grid, .new-releases-grid {
  display: grid;
  gap: 30px;
  max-width: 1200px;
  margin: 0 auto;
}

.new-releases-grid {
  grid-template-columns: repeat(3, 1fr);
  margin-bottom: 60px;
}

.games-grid {
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
}

/* Game card styling */
.game-card {
  background-color: white;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  height: 100%;
}

.game-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.2);
}

.new-release-card {
  position: relative;
}

.new-release-badge {
  position: absolute;
  top: 20px;
  right: 20px;
  background: #FF4D4D;
  color: white;
  padding: 8px 16px;
  border-radius: 20px;
  font-weight: 600;
  font-size: 0.9rem;
  z-index: 1;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.game-link {
  text-decoration: none;
  color: inherit;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.game-image-container {
  width: 100%;
  height: 200px;
  overflow: hidden;
  position: relative;
}

.game-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.game-card:hover .game-image {
  transform: scale(1.05);
}

.game-details {
  padding: 25px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.game-details h2 {
  color: #4F9FD9;
  font-size: 1.8rem;
  margin-bottom: 12px;
  font-family: 'Varela Round', sans-serif;
}

.game-details p {
  color: #2E2E2E;
  font-size: 1.1rem;
  line-height: 1.5;
}

@media (max-width: 1024px) {
  .new-releases-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .games-display-screen {
    padding: 16px;
  }

  .section-title {
    font-size: 2.5rem;
    flex-direction: column;
  }

  .section-subtitle {
    font-size: 2rem;
    margin: 40px 0 20px;
  }

  .new-releases-grid {
    grid-template-columns: 1fr;
  }

  .game-details h2 {
    font-size: 1.5rem;
  }

  .game-details p {
    font-size: 1rem;
  }

  .songly-flag-container {
    width: 100px;
    height: 100px;
  }
}

/* Add these new styles */
.header-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
  padding: 0 20px;
  max-width: 1200px;
  margin: 0 auto 2rem;
  background-color: white;
  border-radius: 20px;
  padding: 20px;
}

.songly-flag-container {
  position: absolute;
  right: 0;
  width: 160px;
  height: 160px;
}

.songly-flag {
  width: 100%;
  height: 100%;
  object-fit: contain;
  transition: transform 0.3s ease;
}

.songly-flag:hover {
  transform: rotate(-5deg);
}

/* Hover animation */
.songly-flag-container:hover .songly-flag {
  animation: wavingFlagHover 1s ease-in-out infinite;
  filter: drop-shadow(0 6px 12px rgba(0, 0, 0, 0.2));
}

@keyframes wavingFlagHover {
  0%, 100% {
    transform: rotate(0deg) scale(1.05);
  }
  25% {
    transform: rotate(-8deg) scale(1.05);
  }
  75% {
    transform: rotate(8deg) scale(1.05);
  }
}

/* Update responsive styles */
@media (max-width: 1024px) {
  .songly-flag-container {
    width: 140px;
    height: 140px;
  }
  
  .section-title {
    font-size: 3rem;
  }
}

@media (max-width: 768px) {
  .games-display-screen {
    padding-top: 60px;
  }

  .header-container {
    flex-direction: column;
    gap: 20px;
  }

  .songly-flag-container {
    position: relative;
    right: auto;
    top: auto;
    transform: none;
    width: 120px;
    height: 120px;
    margin: 0 auto;
  }

  .section-title {
    font-size: 2.5rem;
  }
}

/* Add entrance animation */
.songly-flag {
  animation: fadeInRight 0.6s ease-out forwards;
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translateX(20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

/* Keep all original styles and just add these new ones */
.title-container {
  position: relative;
  margin-bottom: 20px;
}

.games-content {
  background-color: white;
  border-radius: 30px;
  padding: 25px;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
  position: relative;
}

.songly-flag-container {
  position: absolute;
  right: 0;
  top: 0;
  width: 160px;
  height: 160px;
}

.songly-flag {
  width: 100%;
  height: 100%;
  object-fit: contain;
  transition: transform 0.3s ease;
}

.songly-flag:hover {
  transform: rotate(-5deg);
}

@media (max-width: 768px) {
  .songly-flag-container {
    width: 120px;
    height: 120px;
  }
}

/* Update media query for smaller screens */
@media (max-width: 1240px) {
  .songly-flag-container {
    right: 20px;
  }
}

/* Update these specific styles */
.title-section {
  max-width: 1200px;
  margin: 0 auto 40px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.section-title {
  color: white;
  font-size: 3.5rem;
  text-align: center;
  font-family: 'Varela Round', sans-serif;
}

.songly-flag-container {
  position: absolute;
  right: 0;
  width: 160px;
  height: 160px;
}

.songly-flag {
  width: 100%;
  height: 100%;
  object-fit: contain;
  transition: transform 0.3s ease;
}

.songly-flag:hover {
  transform: rotate(-5deg);
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .title-section {
    flex-direction: column;
    gap: 20px;
  }

  .songly-flag-container {
    position: relative;
    width: 120px;
    height: 120px;
  }

  .section-title {
    font-size: 2.5rem;
  }
}
