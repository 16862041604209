/* General styles */
.game-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: calc(100vh + 220px);
  background-color: #2E2E2E;
  padding: 20px;
  padding-bottom: calc(220px + 2rem);
  overflow-x: hidden;
}

.game-title {
  color: white;
  font-size: 2.5rem;
  margin-bottom: 20px;
  text-align: center;
}

/* Game Container with 4:3 aspect ratio */
.game-container {
  width: 1067px; /* 4:3 ratio with 800px height */
  height: 800px;
  position: relative;
  background-color: #000;
  margin: 0 auto;
}

/* Unity container styles */
.game-container #unity-container {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.game-container #unity-canvas {
  width: 1067px !important;
  height: 800px !important;
}

/* Mobile/Tablet Responsive adjustments */
@media (max-width: 1100px) {
  .game-container {
    width: 100%;
    height: auto;
    aspect-ratio: 4 / 3;
  }

  .game-container #unity-canvas {
    width: 100% !important;
    height: 100% !important;
  }

  .game-title {
    font-size: 2rem;
  }
}

/* Additional iPad-specific adjustments */
@media only screen 
and (min-device-width: 768px) 
and (max-device-width: 1024px) {
  .game-container {
    width: 100%;
    max-width: 1067px;
    height: auto;
    aspect-ratio: 4 / 3;
  }
}
