.keyboard-toggle {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 50px;
  height: 50px;
  border-radius: 25px;
  background: #4F9FD9;
  color: white;
  border: none;
  font-size: 24px;
  cursor: pointer;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  z-index: 999;
  transition: transform 0.2s;
}

.keyboard-toggle:hover {
  transform: scale(1.1);
}

@media (max-width: 768px) {
  .keyboard-toggle {
    bottom: 10px;
    right: 10px;
  }
} 