.draggable-keyboard {
  position: fixed;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  touch-action: none;
}

.keyboard-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  background: #4F9FD9;
  color: white;
  border-radius: 8px 8px 0 0;
}

.octave-button {
  background: rgba(255, 255, 255, 0.1);
  border: 2px solid rgba(255, 255, 255, 0.8);
  color: white;
  padding: 12px 20px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 18px;
  min-width: 50px;
  min-height: 44px;
  margin: 0 5px;
  transition: all 0.15s ease-in-out;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.octave-button:active {
  transform: scale(0.95);
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.octave-display {
  background: rgba(255, 255, 255, 0.1);
  padding: 8px 20px;
  border-radius: 12px;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.5px;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  border: 2px solid rgba(255, 255, 255, 0.3);
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
}

.close-button {
  background: rgba(255, 255, 255, 0.1);
  border: 2px solid rgba(255, 255, 255, 0.8);
  color: white;
  padding: 8px 16px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 20px;
  min-width: 44px;
  min-height: 44px;
  transition: all 0.15s ease-in-out;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.close-button:active {
  transform: scale(0.95);
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.piano-keyboard {
  position: relative;
  height: calc(100% - 40px);
  display: flex;
  padding: 5px;
}

.octave-container {
  display: flex;
  position: relative;
  height: 100%;
  flex: 12; /* Each octave container takes space for 12 keys */
}

.key {
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding-bottom: 5px;
  border: 1px solid #ccc;
  cursor: pointer;
  user-select: none;
  touch-action: none;
  overflow: visible !important; /* Allow rotated labels to extend outside the key */
}

.key.white {
  background: white;
  flex: 1;
  height: 100%;
  z-index: 1;
}

/* Add specific styling for the final A key */
.piano-keyboard > .key.white {
  flex: 1;
  max-width: calc(100% / 25); /* Total white keys in view (14) divided into total space */
}

.key.black {
  background: black;
  width: 30px;
  height: 60%;
  margin: 0 -15px;
  z-index: 2;
}

.key.white:active,
.key.white.active {
  background: #e0e0e0;
}

.key.black:active,
.key.black.active {
  background: #333;
}

.note-label {
  font-size: 10px;
  color: #666;
}

.key.black .note-label {
  color: white;
}

/* Mobile-specific styles */
@media (max-width: 768px) {
  .draggable-keyboard {
    position: fixed !important;
    bottom: 20px !important;
    left: 20px !important;
    width: calc(100% - 40px) !important;
    height: 200px !important;
    transform: none !important;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    touch-action: none;
  }

  .draggable-keyboard.mobile {
    cursor: move;
    user-select: none;
  }

  .keyboard-controls {
    cursor: move;
    touch-action: none;
  }

  .keyboard-controls {
    padding: 12px;
  }

  .octave-button {
    padding: 15px 25px;
    font-size: 20px;
    margin: 0 8px;
  }

  .octave-display {
    font-size: 20px;
    padding: 12px 25px;
  }

  .close-button {
    padding: 12px 20px;
    font-size: 24px;
  }

  /* Add touch feedback for mobile */
  .octave-button:active,
  .close-button:active {
    transform: scale(0.92);
    transition: transform 0.1s ease-in-out;
  }

  .piano-keyboard {
    height: calc(100% - 68px);
  }

  .key.white {
    min-width: 40px;
  }

  .key.black {
    min-width: 25px;
  }

  .note-label {
    font-size: 12px;
  }
}

/* Add this with your other button styles */
.start-note-button {
  background: rgba(255, 255, 255, 0.1);
  border: 2px solid rgba(255, 255, 255, 0.8);
  color: white;
  padding: 12px 20px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 18px;
  min-width: 100px;
  min-height: 44px;
  margin: 0 5px;
  transition: all 0.15s ease-in-out;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.start-note-button:active {
  transform: scale(0.95);
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
  .start-note-button {
    padding: 15px 25px;
    font-size: 20px;
    margin: 0 8px;
  }
  
  .start-note-button:active {
    transform: scale(0.92);
    transition: transform 0.1s ease-in-out;
  }
}

/* Add these styles for the MusicKombat key labels */
.combat-label-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  z-index: 10;
}

.combat-label {
  transform-origin: center center;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 2px 4px;
  border-radius: 2px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
}

.key {
  position: relative;
  overflow: visible !important;
}

.key.white {
  background: white;
  z-index: 1;
}

.key.black {
  background: black;
  z-index: 2;
}

.key.white .combat-label {
  background-color: rgba(255, 255, 255, 0.9);
}

.key.black .combat-label {
  background-color: rgba(255, 255, 255, 0.9);
} 