/* Base styles for the navbar */
.navbar {
  background-color: rgba(0, 0, 0, 0.1); 
  padding: 10px 20px; 
  display: flex;
  align-items: center;
  width: 100%;
  box-sizing: border-box; 
  overflow: hidden; 
}

/* Navbar content container */
.navbar-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
  padding: 0 20px;
  margin: 0 auto;
}

/* Logo styling */
.navbar-logo {
  height: 50px;
  margin-right: auto;
}

/* Navigation links container */
.navbar-links {
  list-style-type: none;
  display: flex;
  gap: 30px;
  padding: 0;
  margin: 0; 
}

/* Individual link styling */
.navbar-links li {
  font-size: 1.2rem;
  margin: 0; 
}

.navbar-links a {
  color: white;
  text-decoration: none;
  transition: color 0.3s ease;
  margin-right: 40px;
}

.navbar-links a:hover {
  color: #00B2B2;
}

.navbar-text {
  color: white;
  font-size: 1.2rem;
}

@media (max-width: 768px) {
  .navbar {
    padding: 8px;
  }

  .navbar-content {
    flex-direction: column;
    gap: 12px;
  }

  .navbar-links {
    flex-direction: column;
    gap: 12px;
    width: 100%;
    text-align: center;
  }

  .navbar-links a {
    margin-right: 0;
  }

  .navbar-logo {
    height: 40px;
  }
}
