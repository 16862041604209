/* LessonsScreen.css */
@import url('https://fonts.googleapis.com/css2?family=Varela+Round&display=swap');

* {
  font-family: 'Varela Round', sans-serif;
}

/* Base container for the lessons screen */
.lessons-screen {
  min-height: 100vh;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Main card styling */
.lessons-card {
  position: relative;
  background-color: white;
  border-radius: 24px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
  padding: 24px;
  width: 100%;
  max-width: 1200px;
}

.curriculum-image-wrapper {
  position: absolute;
  top: 0;
  right: 24px;
  width: 160px;
  height: 160px;
  animation: floatImage 6s ease-in-out infinite;
  z-index: 1;
}

.curriculum-mascot {
  width: 100%;
  height: 100%;
  object-fit: contain;
  filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.1));
}

.lessons-header {
  text-align: center;
  margin-bottom: 24px;
  padding: 0;
  position: relative;
  width: 100%;
}

.lessons-title {
  font-size: 2.5rem;
  color: #4F9FD9;
  margin: 0 auto;
  max-width: 80%;
}

.lessons-subtitle {
  font-size: 1.25rem;
  color: black;
  margin: 10px auto 0;
}

/* Tutorial button styling */
.tutorial-container {
  text-align: center;
  margin-bottom: 24px;
}

.tutorial-button {
  background-image: linear-gradient(to right, #fbbf24, #f59e0b);
  color: white;
  padding: 12px 24px;
  font-size: 1.25rem;
  font-weight: bold;
  border-radius: 9999px;
  cursor: pointer;
  width: 100%; 
  max-width: 400px;
  border: none;
  transition: background-color 0.3s ease, transform 0.3s ease;
  text-decoration: none;
}

.tutorial-button:hover {
  transform: scale(1.05);
}

/* Lessons list styling */
.lessons-list {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin: 0 auto;
  margin-bottom: 10px;
}

/* 
   Default Lesson item styles 
   (we'll override colors in the specialized classes below)
*/
.lesson-item {
  background-color: #ffffff;
  border-radius: 16px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
  transition: transform 0.3s ease;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
}

.lesson-item:hover {
  transform: scale(1.05);
}

.lesson-info {
  display: flex;
  align-items: center;
  flex-grow: 1; 
}

.lesson-icon {
  background-color: #edf2f7;
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-right: 16px;
}

/* Default text color (will override in type classes if needed) */
.lesson-title {
  font-size: 1.25rem;
  color: #4F9FD9;
  flex-grow: 1; 
}

/* Default button style (will override in type classes) */
.lesson-button {
  background-image: linear-gradient(to right, #4F9FD9, #9CD8FA);
  color: white;
  padding: 12px 24px;
  border-radius: 9999px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  font-size: 1.25rem;
  outline: none;
  border: none;
}

.lesson-button:hover {
  transform: scale(1.05);
}

/* Tutorial and Popup specific styling */
.lesson-item:first-child .lesson-title,  /* Tutorial */
.lesson-popup .lesson-title {  /* Popup Demo Concert */
  color: #fbbf24;  /* Yellow */
}

.lesson-item:first-child .lesson-button,  /* Tutorial */
.lesson-popup .lesson-button {  /* Popup Demo Concert */
  background-image: linear-gradient(to right, #fbbf24, #f59e0b);  /* Yellow gradient */
}

.lesson-item:first-child .icon,  /* Tutorial */
.lesson-popup .icon {  /* Popup Demo Concert */
  color: #fbbf24;
  stroke: #fbbf24;
}

/* ========================= */
/* Per-lesson color overrides */
/* ========================= */

/* 
   Rhythm: orange 
   (Icon & text = #f97316, #ea580c, 
    button gradient from #f97316 to #ea580c)
*/
.lesson-rhythm .icon, 
.lesson-rhythm .lesson-title {
  color: #f97316; /* For text, or use stroke for the icon if needed */
  stroke: #f97316;
}
.lesson-rhythm .lesson-button {
  background-image: linear-gradient(to right, #f97316, #ea580c);
}

/* 
   Harmony: blue 
   (Icon & text = #3b82f6,
    button gradient from #3b82f6 to #2563eb)
*/
.lesson-harmony .icon, 
.lesson-harmony .lesson-title {
  color: #3b82f6;
  stroke: #3b82f6;
}
.lesson-harmony .lesson-button {
  background-image: linear-gradient(to right, #3b82f6, #2563eb);
}

/* 
   Instrument: dark purple 
   (Icon & text = #7c3aed,
    button gradient from #7c3aed to #6d28d9)
*/
.lesson-instrument .icon, 
.lesson-instrument .lesson-title {
  color: #7c3aed;
  stroke: #7c3aed;
}
.lesson-instrument .lesson-button {
  background-image: linear-gradient(to right, #7c3aed, #6d28d9);
}

/* 
   Melody: red
   (Icon & text = #ef4444,
    button gradient from #ef4444 to #dc2626)
*/
.lesson-melody .icon, 
.lesson-melody .lesson-title {
  color: #ef4444;
  stroke: #ef4444;
}
.lesson-melody .lesson-button {
  background-image: linear-gradient(to right, #ef4444, #dc2626);
}

/* 
   Form: green
   (Icon & text = #10b981,
    button gradient from #10b981 to #059669)
*/
.lesson-form .icon, 
.lesson-form .lesson-title {
  color: #10b981;
  stroke: #10b981;
}
.lesson-form .lesson-button {
  background-image: linear-gradient(to right, #10b981, #059669);
}

/* 
   Dynamics & Showcase: gold
   (Icon & text = #fbbf24,
    button gradient from #fbbf24 to #f59e0b)
*/
.lesson-dynamics .icon, 
.lesson-dynamics .lesson-title,
.lesson-showcase .icon, 
.lesson-showcase .lesson-title {
  color: #fbbf24;
  stroke: #fbbf24;
}
.lesson-dynamics .lesson-button,
.lesson-showcase .lesson-button {
  background-image: linear-gradient(to right, #fbbf24, #f59e0b);
}

/* Keep yellow for popup/tutorial related items */
.lesson-popup .icon, 
.lesson-popup .lesson-title {
  color: #fbbf24;
  stroke: #fbbf24;
}

.lesson-popup .lesson-button {
  background-image: linear-gradient(to right, #fbbf24, #f59e0b);
}

@keyframes floatImage {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Mobile and tablet responsiveness */
@media (max-width: 768px) {
  .lessons-screen {
    padding: 8px;
  }

  .lessons-card {
    padding: 16px;
  }

  .lessons-title {
    font-size: 2rem;
    max-width: 100%;
  }

  .lesson-item {
    padding: 12px 16px;
    flex-direction: column;
    gap: 12px;
    align-items: flex-start;
  }

  .lesson-button {
    width: 100%;
    padding: 8px 16px;
  }

  .lesson-info {
    width: 100%;
  }

  .curriculum-image-wrapper {
    position: relative;
    top: 0;
    right: 0;
    width: 120px;
    height: 120px;
    margin: 0 auto 20px;
  }

  .lessons-header {
    padding: 0;
    text-align: center;
  }
}

/* Medium screens */
@media (min-width: 769px) and (max-width: 1024px) {
  .curriculum-image-wrapper {
    width: 140px;
    height: 140px;
  }

  .lessons-header {
    padding-right: 160px;
  }
}

/* Add a specific breakpoint for when text starts to crowd the image */
@media (max-width: 600px) {
  .lessons-card {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .curriculum-image-wrapper {
    order: -1; /* Move image above title */
    margin-bottom: 16px;
  }

  .lessons-title {
    font-size: 1.8rem;
  }
}

/* Add smooth transitions */
.curriculum-image-wrapper, .lessons-title {
  transition: all 0.3s ease-in-out;
}

