/* Base styles */
@import url('https://fonts.googleapis.com/css2?family=Varela+Round&display=swap');

* {
  font-family: 'Varela Round', sans-serif;
  color: #2E2E2E;
}

/* Style for the lesson-plan page container */
.lesson-plan {
  max-width: 80%;
  margin: 20px auto;
  background-color: white;
  padding: 40px;
  border-radius: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

/* Flex container for the sections */
.lesson-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 40px;
}

/* Style for each section box */
.lesson-plan section {
  margin-top: 20px;
  background-color: #fff;
  border-radius: 15px;
  padding: 20px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  flex: 1 1 calc(50% - 20px);
  box-sizing: border-box;
  min-width: 300px;
  transition: transform 0.3s ease;
}

.lesson-plan section:hover {
  transform: scale(1.05);
}

p {
  font-size: 1.2rem;
  color: #2E2E2E;
}

/* Style for the lesson title */
.lesson-plan h1 {
  text-align: center;
  font-size: 2.5rem;
  color: #4F9FD9;
  width: 100%;
  font-family: 'Varela Round', sans-serif;
  margin-bottom: 20px;
}

/* General text styling for section headers */
.lesson-plan h2 {
  font-size: 2rem;
  color: #4F9FD9;
  margin-bottom: 10px;
  font-family: 'Varela Round', sans-serif;
}

.lesson-plan h3 {
  font-size: 1.5rem;
  color: #4F9FD9;
  margin-bottom: 20px;
}

/* Styling for lists within sections */
.lesson-plan ul {
  list-style-type: none;
  padding-left: 0;
  color: black;
}

/* Styling for list items */
.lesson-plan li {
  margin-bottom: 10px;
  font-size: 1.0rem;
  color: black;
}

/* Centered and larger Present button container */
.present-button-container {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}

/* Present button styling */
.present-button-lessonplan {
  background-color: #4F9FD9;
  color: white;
  padding: 16px 40px; 
  font-size: 1.25rem;
  font-weight: bold;
  border-radius: 9999px;
  cursor: pointer;
  border: none;
  width: 300px;
  text-align: center;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.present-button-lessonplan:hover {
  background-color: #00B2B2;
  transform: scale(1.05);
}

/* Responsive design: Stacks sections vertically on smaller screens */
@media (max-width: 768px) {
  .lesson-plan {
    max-width: 95%;
    padding: 20px;
  }

  .lesson-content {
    gap: 20px;
  }

  .lesson-plan section {
    min-width: 100%;
  }

  .lesson-plan h1 {
    font-size: 2rem;
  }

  .lesson-plan h2 {
    font-size: 1.5rem;
  }

  .present-button-lessonplan {
    width: 100%;
    max-width: none;
  }
}

.pdf-container {
  margin-top: 20px; /* This is optional in case you want spacing on both elements */
}

/* Accordion styling */
.MuiAccordion-root {
  border: 1px solid rgba(79, 159, 217, 0.2) !important;
}

.MuiAccordionSummary-root {
  background-color: rgba(79, 159, 217, 0.05) !important;
}

.MuiAccordionSummary-root.Mui-expanded {
  background-color: rgba(79, 159, 217, 0.1) !important;
}
