@import url('https://fonts.googleapis.com/css2?family=Varela+Round&display=swap');

body {
  font-family: 'Varela Round', sans-serif;
}

/* Base styles for the App */
.App {
  text-align: center;
  background: linear-gradient(135deg, #4F9FD9, #9CD8FA);
  min-height: 100vh;
}


/* Header styles */
.App-header {
  background: transparent;
  padding: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Links for the tech-like theme */
.App-link {
  color: #2E2E2E;
  text-decoration: none;
  font-size: 1.2rem;
  margin: 0 15px;
}

/* Add a cool hover effect for links */
.App-link:hover {
  color: #00B2B2;
  text-decoration: underline;
}

/* Add modern, clean styles for the landing page and buttons */
.button {
  background-color: #4F9FD9;
  border: none;
  padding: 15px 30px;
  color: white;
  font-size: 1.2rem;
  cursor: pointer;
  border-radius: 5px;
  margin-top: 20px;
  transition: background-color 0.3s ease;
}

.button:hover {
  background-color: #00B2B2;
}

h1, h2 {
  font-family: 'Varela Round', sans-serif;
  font-weight: bold;
  margin: 10px;
  color: #2E2E2E;
}
