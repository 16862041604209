/* Base styles for the home screen */
.home-container {
  min-height: 100vh;
  padding: 20px;
  background: linear-gradient(135deg, #4F9FD9, #9CD8FA);
  display: flex;
  justify-content: center;
  align-items: center;
}

.main-content {
  background-color: rgba(255, 255, 255, 0.98);
  border-radius: 30px;
  padding: 25px;
  width: 100%;
  max-width: 1200px;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
  min-height: calc(100vh - 40px);
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
}

.content-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-bottom: 100px;
}

.welcome-title {
  font-size: 2rem;
  margin-bottom: 15px;
  text-align: center;
  font-weight: 800;
  background: linear-gradient(45deg, #4F9FD9, #b642f5);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-image-container {
  flex: 1;
  display: grid;
  grid-template-columns: 1fr 0.8fr; /* Adjusted ratio */
  gap: 20px;
  margin-bottom: px;
  min-height: 0; /* Important for preventing overflow */
}

.text-section {
  display: flex;
  flex-direction: column;
  gap: 15px;
  min-height: 0; /* Important for preventing overflow */
}

.info-section {
  flex-shrink: 0;
}

.section-title {
  font-size: 1.2rem;
  color: #2c3e50;
  margin-bottom: 10px;
  font-weight: 700;
}

.text-section h2.section-title {
  font-size: 1.2rem;
  color: #2c3e50;
  margin-bottom: 10px;
  font-weight: 700;
  width: 100%;
  text-align: left;
}

.custom-numbered-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.custom-numbered-list li {
  margin-bottom: 8px;
}

.list-highlight {
  font-size: 1rem;
  font-weight: 600;
  color: #4F9FD9;
  margin-bottom: 2px;
}

.list-detail {
  color: #666;
  font-size: 0.9rem;
  margin: 0;
}

.steps-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
}

.step-item {
  text-align: center;
  padding: 10px;
  background: rgba(79, 159, 217, 0.1);
  border-radius: 15px;
}

.step-number {
  width: 30px;
  height: 30px;
  background: #4F9FD9;
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 8px;
  font-weight: bold;
}

.image-section {
  width: 100%;
  height: 100%;
  max-height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.songly-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
}

.songly-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  transition: transform 0.3s ease-in-out;
  animation: float 6s ease-in-out infinite;
}

@keyframes float {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-15px);
  }
  100% {
    transform: translateY(0px);
  }
}

.start-link-container {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 40px);
  max-width: 500px;
  z-index: 1000;
  padding: 0;
  margin: 0;
  text-align: center;
}

.start-link {
  font-size: 1.2rem;
  color: white;
  background: linear-gradient(45deg, #4F9FD9, #b642f5);
  border: none;
  padding: 12px 30px;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: 600;
  display: inline-flex;
  align-items: center;
  gap: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  width: 100%;
  justify-content: center;
  transform: translateY(0);
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  position: relative;
  overflow: hidden;
  animation: pulse 3s infinite;
}

/* Add hover effects */
.start-link:hover {
  transform: translateY(-2px);
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.2);
  background: linear-gradient(45deg, #5aafee, #c94dff);
}

/* Add click/active effect */
.start-link:active {
  transform: translateY(1px);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

/* Add ripple effect */
.start-link::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  background-image: radial-gradient(circle, #fff 10%, transparent 10.01%);
  background-repeat: no-repeat;
  background-position: 50%;
  transform: scale(10, 10);
  opacity: 0;
  transition: transform .5s, opacity 1s;
}

.start-link:active::after {
  transform: scale(0, 0);
  opacity: 0.3;
  transition: 0s;
}

/* Add subtle pulse animation */
@keyframes pulse {
  0% {
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  }
  50% {
    box-shadow: 0 4px 25px rgba(79, 159, 217, 0.3);
  }
  100% {
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  }
}

/* Add arrow animation */
.arrow {
  transition: transform 0.3s ease;
  display: inline-block;
}

.start-link:hover .arrow {
  transform: translateX(5px);
}

/* Disable hover effects on mobile devices for better performance */
@media (hover: none) {
  .start-link:hover {
    transform: none;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    background: linear-gradient(45deg, #4F9FD9, #b642f5);
  }
  
  .start-link:hover .arrow {
    transform: none;
  }
}

/* Mobile Styles */
@media (max-width: 768px) {
  .home-container {
    padding: 10px;
  }

  .main-content {
    padding: 20px;
    min-height: calc(100vh - 20px);
  }

  .content-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-bottom: 80px;
  }

  .text-image-container {
    grid-template-columns: 1fr;
    gap: 10px;
    flex: 1;
  }

  .image-section {
    max-height: none;
    height: auto;
    position: relative;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .songly-container {
    position: relative;
    width: 100%;
    padding-bottom: 20px;
  }

  .songly-image {
    max-width: 80%;
    height: auto;
    margin: 0 auto;
    display: block;
  }

  .welcome-title {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }

  .section-title {
    font-size: 1.1rem;
  }

  .steps-container {
    grid-template-columns: repeat(3, 1fr);
  }

  .start-link-container {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% - 40px);
    z-index: 1000;
    margin: 0 auto;
    padding: 0 20px;
  }

  .content-wrapper {
    padding-bottom: 100px;
  }

  .vibe-title {
    font-size: 1.8rem;
    gap: 10px;
    margin: 5px 0 20px;
  }

  .vibe-hover {
    font-size: 2rem;
    letter-spacing: 1px;
  }
}

/* Add styles for larger mobile devices and tablets */
@media (min-width: 480px) and (max-width: 768px) {
  .songly-image {
    max-width: 60%;
  }
}

/* Add tablet styles */
@media (min-width: 769px) {
  .start-link-container {
    max-width: 400px;
    bottom: 30px;
  }

  .start-link {
    padding: 15px 40px;
  }

  .text-image-container {
    margin-bottom: 0;
  }
}

/* Add tablet styles */
@media (min-width: 769px) and (max-width: 1024px) {
  .text-image-container {
    grid-template-columns: 1fr 1fr;
  }

  .image-section {
    max-height: 350px;
  }
}

/* Add larger screen optimization */
@media (min-width: 1440px) {
  .main-content {
    max-width: 1400px;
  }

  .image-section {
    max-height: 500px;
  }

  .start-link-container {
    bottom: 40px;
  }

  .vibe-title {
    font-size: 2.4rem;
  }

  .vibe-hover {
    font-size: 2.6rem;
  }
}

/* Update the vibe title styles */
.vibe-title {
  font-size: 2.2rem;
  text-align: center;
  margin: 10px 0 30px; /* Increased margin to prevent cutoff */
  display: flex;
  justify-content: center;
  gap: 15px;
  flex-wrap: wrap;
  font-family: 'Poppins', sans-serif;
  font-style: italic;
  font-weight: 600;
  padding: 10px; /* Add padding to prevent cutoff */
  line-height: 1.4; /* Better line height */
}

.vibe-word {
  background: linear-gradient(45deg, #2193b0, #6dd5ed); /* Cool blue gradient */
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  opacity: 0;
  animation: fadeInUp 0.5s ease forwards;
  transform: translateY(20px);
  padding: 0 2px; /* Prevent text cutoff */
}

.vibe-hover {
  font-family: 'Righteous', cursive;
  font-style: normal;
  background: linear-gradient(45deg, #FF3CAC, #784BA0, #2B86C5);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  position: relative;
  cursor: pointer;
  opacity: 0;
  font-size: 2.4rem;
  letter-spacing: 2px;
  padding: 0 4px;
  margin: 0 2px;
  transform-origin: center;
  animation: fadeInUp 0.5s ease forwards;
  animation-delay: 0.8s;
}

/* Add permanent glow */
.vibe-hover {
  text-shadow: 
    0 0 7px rgba(255, 60, 172, 0.3),
    0 0 10px rgba(120, 75, 160, 0.3),
    0 0 21px rgba(43, 134, 197, 0.3);
}

/* Updated hover effect */
.vibe-hover:hover {
  background: linear-gradient(45deg, #FF3CAC, #784BA0, #2B86C5);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  transform: scale(1.15) rotate(-2deg);
  text-shadow: 
    0 0 14px rgba(255, 60, 172, 0.5),
    0 0 20px rgba(120, 75, 160, 0.5),
    0 0 42px rgba(43, 134, 197, 0.5);
  transition: all 0.3s ease;
}

/* Remove the neon-pulse animation and update the underline effect */
.vibe-hover::before {
  content: '';
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100%;
  height: 4px;
  background: linear-gradient(90deg, #FF3CAC, #784BA0, #2B86C5);
  transform: scaleX(0);
  transform-origin: right;
  transition: transform 0.4s cubic-bezier(0.65, 0, 0.35, 1);
  border-radius: 2px;
}

.vibe-hover:hover::before {
  transform: scaleX(1);
  transform-origin: left;
}

/* Staggered animation delays */
.vibe-word:nth-child(1) { animation-delay: 0.2s; }
.vibe-word:nth-child(2) { animation-delay: 0.4s; }
.vibe-word:nth-child(3) { animation-delay: 0.6s; }

/* Smoother fade in */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}