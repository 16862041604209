/* PresentScreen.css */
/* Import Google Font */
@import url('https://fonts.googleapis.com/css2?family=Varela+Round&display=swap');

* {
  font-family: 'Varela Round', sans-serif;
  color: #2E2E2E;
  box-sizing: border-box;
}

/* Container for the present screen */
.present-screen {
  max-width: 90%;
  margin: 20px auto;
  background-color: white;
  padding: 40px;
  border-radius: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

/* Flex container for the content */
.present-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  gap: 40px;
  width: 100%;
}

/* Content container on the left */
.content-container {
  flex: 1;
  max-width: 60%;
  background-color: rgba(79, 159, 217, 0.05);
  border-radius: 15px;
  padding: 0;
  box-shadow: 0 4px 10px rgba(79, 159, 217, 0.2);
  min-height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

/* Media container for video and images */
.media-container {
  width: 100%;
  aspect-ratio: 16 / 9;
  position: relative;
  overflow: hidden;
  border-radius: 15px;
}

/* Style for the iframe video element */
.media-container iframe,
.media-container img {
  width: 100%;
  height: 100%;
  border: none;
  border-radius: 15px;
  display: block;
}

/* Unity game container inside the content container */
.unity-game-container {
  width: 100%;
  aspect-ratio: 16 / 9;
  position: relative;
  overflow: hidden;
  border-radius: 15px;
}

/* Ensure Unity canvas fills the container */
.unity-game-container canvas {
  width: 100% !important;
  height: 100% !important;
  border-radius: 15px;
}

/* Remove background color behind Unity game */
.unity-game-container {
  background-color: transparent;
}

/* Instructions container on the right */
.instructions-container {
  flex: 1;
  max-width: 35%;
  background-color: #f9f9f9;
  border-radius: 15px;
  padding: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  text-align: left;
}

/* Center the title inside instructions-container */
.instructions-container h3 {
  text-align: center;
}

/* Adjust instruction text alignment */
.instruction-section,
.section-header,
.activities-list,
.activity-item {
  text-align: left;
}

/* Title and subtitle styles */
.present-screen h1 {
  text-align: center;
  font-size: 2.5rem;
  color: #4F9FD9;
  margin-bottom: 20px;
}

.present-screen h2 {
  text-align: center;
  font-size: 2rem;
  color: #4F9FD9;
  margin-bottom: 10px;
}

.present-screen h3 {
  font-size: 1.5rem;
  color: #4F9FD9;
  margin-bottom: 20px;
}

/* Back and Next buttons container */
.present-controls {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1240px;
  margin-top: 20px;
}

/* Styling for buttons */
.present-button {
  background-color: #a847e4;
  color: white;
  padding: 12px 24px;
  font-size: 1.25rem;
  font-weight: bold;
  border-radius: 9999px;
  cursor: pointer;
  border: none;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.present-button:hover {
  background-color: #cd84f2;
  transform: scale(1.05);
}

/* Adjust responsive styles */
@media (max-width: 1024px) {
  .present-content {
    flex-direction: column;
  }

  .content-container,
  .instructions-container {
    max-width: 100%;
    width: 100%;
  }

  .present-controls {
    flex-direction: column;
    gap: 16px;
  }

  .present-button {
    width: 100%;
  }

  .present-screen h1 {
    font-size: 2rem;
  }

  .present-screen h2 {
    font-size: 1.5rem;
  }
}
