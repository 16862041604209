.curriculums-screen {
  min-height: 100vh;
  background: linear-gradient(135deg, #4F9FD9, #9CD8FA);
  padding: 40px 20px;
}

.curriculums-content {
  max-width: 1400px;
  margin: 0 auto;
}

.main-title {
  color: white;
  font-size: 3.5rem;
  margin-bottom: 20px;
  text-align: center;
  font-family: 'Varela Round', sans-serif;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.program-section {
  background: rgba(255, 255, 255, 0.98);
  border-radius: 30px;
  padding: 40px;
  margin-bottom: 40px;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
}

.section-title {
  text-align: center;
  margin-bottom: 40px;
}

.gradient-text {
  font-size: 2.8rem;
  font-weight: 800;
  background: linear-gradient(45deg, #4F9FD9, #b642f5);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  display: block;
  margin-bottom: 10px;
}

.section-subtitle {
  font-size: 1.2rem;
  color: #666;
  font-weight: normal;
}

.curriculums-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  gap: 30px;
}

.curriculum-card {
  background-color: white;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.08);
  transition: all 0.3s ease;
  cursor: pointer;
  border: 1px solid rgba(0, 0, 0, 0.05);
}

.curriculum-card:hover {
  transform: translateY(-5px) scale(1.02);
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.15);
}

.curriculum-image {
  width: 100%;
  height: 250px;
  overflow: hidden;
  position: relative;
  background: linear-gradient(45deg, rgba(79, 159, 217, 0.05), rgba(182, 66, 245, 0.05));
}

.curriculum-image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  transition: transform 0.5s ease;
}

.curriculum-card:hover .curriculum-image img {
  transform: scale(1.05);
}

.curriculum-details {
  padding: 25px;
}

.curriculum-details h3 {
  color: #2c3e50;
  font-size: 1.5rem;
  margin-bottom: 12px;
  font-weight: 700;
}

.curriculum-details p {
  color: #666;
  font-size: 1rem;
  line-height: 1.5;
  margin-bottom: 20px;
  min-height: 3em;
}

.lesson-count {
  display: inline-block;
  padding: 8px 16px;
  background: linear-gradient(45deg, rgba(79, 159, 217, 0.1), rgba(182, 66, 245, 0.1));
  border-radius: 20px;
  color: #4F9FD9;
  font-weight: 600;
  font-size: 0.9rem;
}

/* Mobile Styles */
@media (max-width: 768px) {
  .curriculums-screen {
    padding: 20px 10px;
  }

  .main-title {
    font-size: 2.5rem;
  }

  .program-section {
    padding: 20px;
    margin-bottom: 20px;
  }

  .gradient-text {
    font-size: 2rem;
  }

  .section-subtitle {
    font-size: 1rem;
  }

  .curriculums-grid {
    grid-template-columns: 1fr;
    gap: 20px;
  }

  .curriculum-card {
    max-width: 100%;
  }
}

/* Animation for cards */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.curriculum-card {
  animation: fadeInUp 0.5s ease forwards;
  opacity: 0;
}

.curriculum-card:nth-child(1) { animation-delay: 0.1s; }
.curriculum-card:nth-child(2) { animation-delay: 0.2s; }
.curriculum-card:nth-child(3) { animation-delay: 0.3s; } 