@import url('https://fonts.googleapis.com/css2?family=Varela+Round&display=swap');

.canva-screen {
  padding: 30px;
  text-align: center;
  font-family: 'Varela Round', sans-serif;
  background-color: #f8f9fa;
  min-height: 100vh;
}

/* Embedding the Google Doc */
.embed-iframe {
  display: block;
  margin: 0 auto 20px auto; /* Center horizontally + bottom margin */
  width: 100%;
  max-width: 800px;
  height: 600px; /* Adjust as needed */
  border: none;
  box-shadow: 0 2px 8px rgba(63, 69, 81, 0.16);
  border-radius: 8px;
}

.message {
  font-size: 1.2rem;
  margin-bottom: 20px;
  color: #333;
}

/* Button Container */
.button-container {
  display: flex;
  justify-content: center;
  gap: 24px;
}

/* Shared Button Style: White with Purple Outline */
.shared-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 12px 28px;
  font-size: 1.25rem;
  font-weight: bold;
  color: #4F9FD9;
  background-color: #fff;
  border: 2px solid #4F9FD9;
  border-radius: 9999px;
  cursor: pointer;
  transition: transform 0.3s ease, background-color 0.3s ease, color 0.3s ease;
}

.shared-button:hover {
  transform: scale(1.05);
  background-color: #00B2B2;
  border-color: #00B2B2;
  color: #fff;
}

.shared-button:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(79, 159, 217, 0.4);
}

@media (max-width: 768px) {
  .canva-screen {
    padding: 16px;
  }

  .embed-iframe {
    height: 400px;
  }

  .button-container {
    flex-direction: column;
    gap: 16px;
  }

  .shared-button {
    width: 100%;
    padding: 10px 20px;
    font-size: 1rem;
  }
}
